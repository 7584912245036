import React, { useEffect } from 'react'
import { Helmet } from "react-helmet"
import { navigate } from 'gatsby'
import { CheckToken } from '../helpers/utils'
import DashboardLayout from '../layouts/dashboardlayout'
import DownloadOrders from '../components/orders/downloadOrdersCard'
import SearchOrdersCard from '../components/orders/searchOrdersCard'

const Orders = () => {
  useEffect(() => {
    if (!CheckToken()) {
      navigate('/404')
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Winery Portal - Orders</title>
      </Helmet>
      <DashboardLayout>
        <>
          <DownloadOrders />
          <SearchOrdersCard />
        </>
      </DashboardLayout>
    </>
  )
}

export default Orders

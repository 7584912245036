import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  Input,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Spinner,
  Dropdown,
} from 'reactstrap'
import Select from 'react-select'
import Typed from "react-typed"
import {
  requestGetWithKey,
  requestGetWithParam,
  dowloadFilePDFWithParams,
  downloadTextFormat,
  dowloadFile,
  downloadTsvFormat,
  dowloadFileTXTWithParams,
  dowloadFileTSVWithParams,
  dowloadFileCSVWithParams,
  dowloadFilePDF,
  removeObjectNull,
} from '../../helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faSearch } from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'

const SearchOrdersCard = () => {
  const [invoices, setInvoices] = useState(null)
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState(null)
  const [location, setLocation] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startDateFormatChange, setStartDateFormatChange] = useState(null)
  const [endDateFormatChange, setEndDateFormatChange] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [selectedValueLocation, setSelectedValueLocation] = useState(null)
  const [campaigns, setCampaigns] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState(false)
  const [loadingMessageLong, setLoadingMessageLong] = useState(false);
  const [loadingMessageTooLong, setLoadingMessageTooLong] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  const [emptyMessage, setEmptyMessage] = useState(false)
  const [selfWinery, setSelfWinery] = useState(null)
  const [selectedFormats, setSelectedFormats] = useState([])

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  useEffect(() => {
    const getCampaigns = [`api/zoho/campaigns`]
    requestGetWithKey(getCampaigns).then(result => {
      const temp = result.message.data.map(c => {
        return { value: c.id, label: c.Campaign_Name }
      })
      setCampaigns(temp)
    })
    const selfWineryList = [`api/zoho/accounts/self`]
    requestGetWithKey(selfWineryList).then(result => {
      if (result) {
        setSelfWinery({ value: result.message.data[0].id, label: result.message.data[0].Account_Name })
        fetchWineryFormats(result.message.data[0].id)
      }
    })
  }, [])

  const fetchWineryFormats = async (wineryId) => {
    try {
      const response = await requestGetWithKey([`api/wineries/${wineryId}`])
      if (response) {
        const wineryFormats = response.message.formats.map(format => ({
          value: format.codeName,
          label: format.name,
          listFunction: format.listFunction,
          rowFunction: format.rowFunction
        }))
        setSelectedFormats(wineryFormats)
      }
    } catch (error) {
      console.error('Error fetching winery formats:', error)
    }
  }

  useEffect(() => {
    if (startDate) {
      let startDateChg = moment(startDate, 'DD-MM-YYYY')
      setStartDateFormatChange(startDateChg.format('YYYY-MM-DD'))
    } else {
      setStartDateFormatChange(null)
    }
  }, [startDate])

  useEffect(() => {
    if (endDate) {
      let endDateChg = moment(endDate, 'DD-MM-YYYY')
      setEndDateFormatChange(endDateChg.format('YYYY-MM-DD'))
    } else {
      setEndDateFormatChange(null)
    }
  }, [endDate])

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const options = [
    { value: 'vic', label: 'Victoria' },
    { value: 'nsw', label: 'New South Wales' },
    { value: 'qld', label: 'Queensland' },
    { value: 'act', label: 'Australian Capital Territory' },
    { value: 'sa', label: 'South Australia' },
    { value: 'wa', label: 'Western Australia' },
    { value: 'tas', label: 'Tasmania' },
    { value: 'nt', label: 'Northern Territory' },
    { value: 'cellar door', label: 'Cellar Door' },
  ]
  const toggle = () => setDropdownOpen(prevState => !prevState)
  const search = () => {
    setLoading(true)
    setLoadingMessage(true)
    setErrorMessage(null)
    let params = {
      limit: 200,
      searchValue,
      location: selectedValueLocation?.value,
      campaign: selectedCampaign?.value,
      start: startDateFormatChange,
      end: endDateFormatChange,
    }
    let paramsWithfilter = removeObjectNull(params)
    let getInvoices = [`api/zoho/invoices`, paramsWithfilter]
    requestGetWithParam(getInvoices)
      .then(result => {
        setInvoices(result.message.data)
        setEmptyMessage(null)
      })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setInvoices.length === 0
          setErrorMessage(null)
        }
        if (error.status === false) {
          setErrorMessage(error.data.msg)
          setInvoices(null)
        }
        setErrorMessage(error.data.msg)
        setLoading(false),
        setLoadingMessageLong(false),
        setLoadingMessage(false)
      })
      .finally(() => setLoading(false))
  }

  const dateFormatter = cell => {
    if (cell) {
      return moment(cell, 'YYYY-MM-DD').format('DD/MM/YYYY')
    }
  }
  const loadMessage = () => {
    setTimeout(() => {
      setLoadingMessage(true)
      setLoadingMessageTooLong(false)
      setLoadingMessageLong(false)
    }, 0);
    setTimeout(() => {
      setLoadingMessageLong(true)
      setLoadingMessage(false)
      setLoadingMessageTooLong(false)
    }, 10000);
  }

  var functionHandler = {};

  functionHandler.exportPDFList = () => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let params = {
      // limit: 200,
      searchValue,
      location: selectedValueLocation?.value,
      campaign: selectedCampaign?.value,
      start: startDateFormatChange,
      end: endDateFormatChange,
    }
    let paramsWithfilter = removeObjectNull(params)
    let getPDFList = [`api/zoho/invoices/download`, paramsWithfilter]
    dowloadFilePDFWithParams(getPDFList).then(() => {
        setLoading(false),
        setLoadingMessageLong(false),
        setLoadingMessage(false)
        setEmptyMessage(false)
        setErrorMessage(null)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }
          setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.exportDefaultOrderList = () => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let params = {
      // limit: 200,
      searchValue,
      location: selectedValueLocation?.value,
      campaign: selectedCampaign?.value,
      start: startDateFormatChange,
      end: endDateFormatChange,
    }
    let paramsWithfilter = removeObjectNull(params)
    let getDefaultCSVExport = [`api/zoho/invoices/download/default`, paramsWithfilter]
    dowloadFileCSVWithParams(getDefaultCSVExport).then(() => {
      setLoading(false), setLoadingMessageTooLong(false),
        setLoadingMessageLong(false),
        setLoadingMessage(false)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.exportOrderPerLineList = () => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let params = {
      // limit: 200,
      searchValue,
      location: selectedValueLocation?.value,
      campaign: selectedCampaign?.value,
      start: startDateFormatChange,
      end: endDateFormatChange,
    }
    let paramsWithfilter = removeObjectNull(params)
    let getDefaultCSVExport = [`api/zoho/invoices/download/csv`, paramsWithfilter]
    dowloadFileCSVWithParams(getDefaultCSVExport).then(() => {
      setLoading(false), setLoadingMessageTooLong(false),
        setLoadingMessageLong(false),
        setLoadingMessage(false),
        setEmptyMessage(false)
        setErrorMessage(null)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.exportTxtFormatList = () => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let params = {
      // limit: 200,
      searchValue,
      location: selectedValueLocation?.value,
      campaign: selectedCampaign?.value,
      start: startDateFormatChange,
      end: endDateFormatChange,
    }
    let paramsWithfilter = removeObjectNull(params)
    let getTextFormat = [`api/zoho/invoices/download/txt`, paramsWithfilter]
    dowloadFileTXTWithParams(getTextFormat).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
      setEmptyMessage(false)
      setErrorMessage(null)
      setInvoices(null)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.exportTsvFormat = () => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let params = {
      // limit: 200,
      searchValue,
      location: selectedValueLocation?.value,
      campaign: selectedCampaign?.value,
      start: startDateFormatChange,
      end: endDateFormatChange,
    }
    let paramsWithfilter = removeObjectNull(params)
    let getDefaultCSVExport = [`api/zoho/invoices/download/tsv`, paramsWithfilter]
    dowloadFileTSVWithParams(getDefaultCSVExport).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
      setEmptyMessage(false)
      setInvoices(null)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.exportEParcelFormat = () => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let params = {
      // limit: 200,
      searchValue,
      location: selectedValueLocation?.value,
      campaign: selectedCampaign?.value,
      start: startDateFormatChange,
      end: endDateFormatChange,
    }
    let paramsWithfilter = removeObjectNull(params)
    let getDefaultCSVExport = [`api/zoho/invoices/download/eparcel`, paramsWithfilter]
    dowloadFileCSVWithParams(getDefaultCSVExport).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
      setEmptyMessage(false)
      setErrorMessage(null)
      setInvoices(null)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }

  //single row
  functionHandler.exportPdfRow = row => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let getPDFormat = [`api/zoho/invoices/download?searchValue=${row.id}&singleOrder=true`]
    dowloadFilePDF(getPDFormat).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.exportDefaultCSV = row => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let getDefaultCSVExport = [`api/zoho/invoices/download/default?searchValue=${row.id}&singleOrder=true`]
    dowloadFile(getDefaultCSVExport).then(() => {
      setLoading(false), setLoadingMessageTooLong(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.ordersPerLine = row => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let getDefaultCSVExport = [`api/zoho/invoices/download/csv?searchValue=${row.id}&singleOrder=true`]
    dowloadFile(getDefaultCSVExport).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.txtFormat = row => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let getTextFormat = [`api/zoho/invoices/download/txt?searchValue=${row.id}&singleOrder=true`]
    downloadTextFormat(getTextFormat).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.tsvFormat = row => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let getDefaultCSVExport = [`api/zoho/invoices/download/tsv?searchValue=${row.id}&singleOrder=true`]
    downloadTsvFormat(getDefaultCSVExport).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }
  functionHandler.eParcelFormat = row => {
    setLoading(true)
    setLoadingMessageTooLong(false)
    loadMessage()
    setEmptyMessage(false)
    setErrorMessage(null)
    setInvoices(null)
    let getDefaultCSVExport = [`api/zoho/invoices/download/eparcel?searchValue=${row.id}&singleOrder=true`]
    dowloadFile(getDefaultCSVExport).then(() => {
      setLoading(false),
      setLoadingMessageLong(false),
      setLoadingMessage(false)
    })
      .catch((error) => {
        if (error === 503) {
          setLoadingMessageTooLong(true)
        }
        if (error === 404) {
          setEmptyMessage(true)
          setErrorMessage(null)
        }
        if (error.statusCode === 500) {
          setErrorMessage(error.body.data.msg)
          setEmptyMessage(false)
        }

        setLoading(false),
          setLoadingMessageLong(false),
          setLoadingMessage(false)
      })
  }

  const callFunction = (functionName, param) => {
    if (param) {
      if (typeof window[functionName] === 'function') {
        window[functionName](param)
      } else {
        console.error(`Function ${functionName} does not exist`)
      }
    } else {
      if (typeof window[functionName] === 'function') {
        window[functionName]()
      } else {
        console.error(`Function ${functionName} does not exist`)
      }
    }
  }
  
  return (
    <Card className="border-bottom searchOrderCard">
      <CardBody className="overflow-hidden p-lg-4 card-inside" style={{ height: 'unset', minHeight: '72vh' }}>
        <h1>Search orders</h1>
        <div className="text-left mb-2">
          <div className="d-inline-block w-32 w-lg-32 pr-4">
            <DateRangePicker
              startDate={startDate}
              startDateId="tata-start-date"
              endDate={endDate}
              endDateId="tata-end-date"
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput}
              isOutsideRange={() => false}
              onFocusChange={focusedInput => setFocusedInput(focusedInput)}
              displayFormat="DD/MM/YYYY"
              minimumNights={0}
            />
          </div>
          <div className="d-inline-block w-50 w-lg-25 pr-4">
            <Input
              placeholder="Search name, email, mobile..."
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onKeyPress={e => (e.key === 'Enter' && searchValue ? search() : null)}
              id="searchInput"
            />
          </div>
          <div className="d-inline-block pr-4 searchCampLoc">
            <Select
              placeholder="Search campaigns"
              options={campaigns}
              value={selectedCampaign}
              isClearable
              onChange={value => {
                setSelectedCampaign(value)
              }}
              classNamePrefix="react-select"
              id="selectSearch"
              className="locationSearch"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  minHeight: 0,
                  height: 'calc(1.5em + 0.625rem + 2px)',
                  border: '1px solid #d8e2ef !important',
                  borderRadius: '0.25rem !important',
                  '&:hover': {
                    border: '1px solid #d8e2ef !important',
                    borderRadius: '0.25rem !important',
                  },
                }),
              }}
            />
          </div>
          <div className="d-inline-block pr-4 searchCampLoc">
            <Select
              placeholder="Search location"
              options={options}
              value={selectedValueLocation}
              isClearable
              onChange={value => {
                setSelectedValueLocation(value)
                setLocation(value)
              }}
              classNamePrefix="react-select"
              id="selectLocationSearch"
              className="locationSearch"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  minHeight: 0,
                  height: 'calc(1.5em + 0.625rem + 2px)',
                  border: '1px solid #d8e2ef !important',
                  borderRadius: '0.25rem !important',
                  '&:hover': {
                    border: '1px solid #d8e2ef !important',
                    borderRadius: '0.25rem !important',
                  },
                }),
              }}
            />
          </div>
          <div className="d-inline-block pr-4">
            <Button
              id="searchBtn1"
              className="ml-auto px-5 btn-vino btn-vino-primary"
              transform="down-1 shrink-4"
              onClick={() => search()}
              disabled={!searchValue && !location && !startDate && !endDate && !selectedCampaign}
            >
              Search
            </Button>
          </div>
          <div className="d-inline-block w-lg-20 pr-4">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="relativeDropdown">
              <DropdownToggle
                caret
                className="ml-auto px-5 btn-vino btn-vino-primary"
                transform="down-1 shrink-4"
                style={{ width: 260 }}
                disabled={!searchValue && !location && !startDate && !endDate && !selectedCampaign}
              >
                Export Search Results
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Export</DropdownItem>
                <DropdownItem divider />
                {selectedFormats.map(format => (
                <DropdownItem
                  key={format.value}
                  onClick={() => functionHandler[format.listFunction]()}
                >
                  {format.label}
                </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {!emptyMessage ?
         !loadingMessageTooLong ?
          !loading  && !errorMessage && invoices && invoices.length > 0 && (
          <>
            {invoices.length >= 200 && <span>Displaying first 200 records</span>}
            <div class="no-overflow">
              <BootstrapTable
                id="searchContactsTable"
                bootstrap4
                keyField="id"
                data={invoices}
                headerClasses="bs-table-header"
                rowClasses="bs-table-row"
                columns={[
                  {
                    dataField: 'Invoice_Number',
                    text: 'Order Number',
                    sort: true,
                  },
                  {
                    dataField: 'Invoice_Date',
                    text: 'Purchase Date',
                    sort: true,
                    formatter: dateFormatter,
                  },
                  {
                    dataField: 'Contact_Name.name',
                    text: 'First Name',
                    sort: true,
                  },
                  {
                    dataField: 'Email',
                    text: 'Email',
                    sort: true,
                  },
                  {
                    dataField: 'Shipping_State',
                    text: 'Location',
                    sort: true,
                  },
                  {
                    dataField: 'Number_of_bottles_total',
                    text: 'Bottles',
                    sort: true,
                  },
                  {
                    dataField: 'Order_Table_Product_Value_RT',
                    text: 'Sub Total',
                    sort: true,
                  },
                  {
                    isDummyField: true,
                    dataField: 'actions',
                    text: 'Actions',
                    sort: false,
                    formatter: (cell, row, index) => (
                      <>
                        <UncontrolledDropdown className="relativeDropdown">
                          <DropdownToggle
                            color="link"
                            size="sm"
                            className="text-600 btn-reveal"
                            id={'invoices' + index}
                          >
                            <FontAwesomeIcon
                              icon={faEllipsisH}
                              className="text-secondary"
                              id={'exportIcon' + index}
                              style={{ cursor: 'pointer' }}
                            />
                          </DropdownToggle>
                          <DropdownMenu right className="border py-2">
                            <DropdownItem>Export</DropdownItem>
                            <DropdownItem divider />
                            {selectedFormats.map(format => (
                            <DropdownItem
                              key={format.value}
                              onClick={() => functionHandler[format.rowFunction](row)}
                            >
                              {format.label}
                            </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </>
                    ),
                  },
                ]}
                defaultSorted={[
                  {
                    dataField: 'Full_Name',
                    order: 'asc',
                  },
                ]}
                defaultSortDirection="asc"
              />
            </div>
          </>
          ) :
          <p className="loadingText">
            <span>Looks like the server has run out of patience and is having a time-out. Take a deep breath and try again. But if you see this a couple of times make a screenshot and send us an email because it’s probably more serious than just a grumpy or busy server
            </span>
            </p> :
          <>
            <div className="text-center" id="searchContactsCardMessages2">
              <FontAwesomeIcon icon={faSearch} className="fs-2 mb-2" />
              <h1 className="font-weight-bold mb-2 fs-3">Oops, we didn't find anything</h1>
              <p className="mb-1 fs-1">Try searching for something containing</p>
              <p className="mb-1 fs-1">First name, Last name, Email, Mobile, Phone, Member ID</p>
              <p className="mb-2 fs-1">or check spelling mistakes.</p>
              <h2 className="mb-4 fs-2">Try again.</h2>
            </div>
          </>
        }
        {!loading && !invoices && !errorMessage && !emptyMessage && !loadingMessageTooLong &&(
          <div className="text-center" id="searchContactsCardMessages1">
            <FontAwesomeIcon icon={faSearch} className="fs-2 mb-2" />
            <h1 className="font-weight-bold mb-2 fs-3">What can we get for you?</h1>
            <p className="mb-1 fs-1">Try searching for something containing</p>
            <p className="mb-1 fs-1">First name, Last name, Email, Mobile, Phone, Member ID</p>
          </div>
        )}
        {
          !loading && invoices && invoices.length === 0 && !loadingMessageTooLong &&
          <div className="text-center" id="searchContactsCardMessages2">
            <FontAwesomeIcon icon={faSearch} className="fs-2 mb-2" />
            <h1 className="font-weight-bold mb-2 fs-3">Oops, we didn't find anything</h1>
            <p className="mb-1 fs-1">Try searching for something containing</p>
            <p className="mb-1 fs-1">First name, Last name, Email, Mobile, Phone, Member ID</p>
            <p className="mb-2 fs-1">or check spelling mistakes.</p>
            <h2 className="mb-4 fs-2">Try again.</h2>
          </div>
        }
        {!loading && !invoices && errorMessage && !emptyMessage && !loadingMessageTooLong &&
          <p className="loadingText"><span>{errorMessage}</span></p>
        }
        {loading && !loadingMessageTooLong &&
          <>
          <div className="text-center pt-6">
            <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} />
          </div>
           {loadingMessage ?
          <Typed
            strings={["Please wait, we’re building your file for you. Should take less time than decanting a nice red..."]}
            typeSpeed={10}
            className="loadingText"
          />
          : null}
        {loadingMessageLong ?
          <Typed
            strings={["Well, this is embarrassing. Maybe the server needs more coffee and less wine…? (We’re still waiting though - hopefully won’t be long now)"]}
            typeSpeed={10}
            className="loadingText"
          />
          : null}
          </>  
        }
      </CardBody>
    </Card>
  )
}

export default SearchOrdersCard

import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardImg, CardTitle, CardText, Button, Spinner } from 'reactstrap'
import background1 from '../../assets/img/Header-Images-Warehouse1.jpg'
import { requestGetWithKey, dowloadFilePDF, randomMessage, typedDelay } from '../../helpers/utils'
import CountUp from 'react-countup';
import Typed from 'react-typed';

const DownloadOrders = () => {
  const [orderCount, setOrderCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(null)

  useEffect(() => {
    setLoadingMessage(randomMessage())
    orderCountApiCal()
  }, [])

  const orderCountApiCal = () => {
    setOrderCount(null)
    const orders = [`api/zoho/invoices/count?warehouse=Draft`]
    requestGetWithKey(orders).then(result => {
      setOrderCount(result.message.count ?? 0)
    })
  }

  const exportOrders = () => {
    setLoading(true);
    dowloadFilePDF([`api/zoho/invoices/download?warehouse=Draft`]).finally(() => {
      setLoading(false)
      orderCountApiCal()
      setOrderCount(0)
    });
  }

  return orderCount ? (
    <>
      <Card className="cardHeight" id='yesExportOrders'>
        <CardImg src={background1} />
        <CardBody className="contactBody">
          {!loading ?
            <>
              <CountUp
                end={orderCount}
                duration={1}
                separator=","
                className="cardCount card-title"
              />
              <CardText tag="p" className="cardSubText">
                New orders ready to pack!
              </CardText>
              <Button id="exportContactsButton" className="btn btn-vino btn-vino-primary" onClick={exportOrders}>
                Export PDF
              </Button>
            </> :
            <div className="text-center p-4">
              <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#77b204' }} />
            </div>}
        </CardBody>
      </Card>
    </>
  ) : (
    <>
      <Card className="cardHeight" id='exportOrders'>
        <CardBody className="contactBody">
          <CardTitle tag="h5" className="cardCount cardLight">
            {loadingMessage}
          </CardTitle>
          {orderCount === null ? (
            <Typed
              id='noExportOrders'
              strings={[`fetching your orders${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.${typedDelay}.`]}
              className="cardSubText cardLight"
              showCursor={false}
              typeSpeed={20}
              loop
            />
          ) : (
            <CardText tag="p" className="cardSubText cardLight" id='noExportOrders'>
              No new orders to pack, but you can select a range of orders previously picked below.
            </CardText>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default DownloadOrders
